  <template>
  <div id="stranger-things" v-if="Object.keys(labels).length > 0">
    <div class="wrapper">
      <!-- <div style="background-color:white; color:black; text-align: center;font-size:2em; padding:0.1em 0.4em;">[ Beta Version ]</div> -->
      <div class="film">
        <div id="logo"><h1 class="d-none">Showzam</h1></div>
        <div id="title-image"><h2 class="d-none">Stranger Things</h2></div>
      </div>
      <!--
      <nav class="navbar navbar-expand">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <router-link id="l-home" class="nav-link" :to="`/${show}`">Home</router-link>
          </li>
        </ul>
      </nav>

      <router-view :labels="labels" :country="country" :show="show"></router-view>
      -->

      <StrangerThingsHome :labels="labels" :country="country" :show="show"/>

      <div class="boxContainer d-none" id="sendMessageFormContainer">
        <form id="sendmessageForm" class="guidebox grayrect needs-validation" novalidate>
          <div class="d-flex justify-content-between">
            <div class="w-75">
              <div class="form-group">
                <label for="name">{{ labels[country].sendamessagename }}:</label>
                <input id="name" name="name" type="text" value="" class="form-control" required/>
              </div>
              <div class="form-group">
                <label for="email">{{ labels[country].sendamessageemail }}:</label>
                <input id="email" name="email" type="email" value="" class="form-control"/>
              </div>
            </div>
            <h1 style="text-align:right">
              <a @click="sendMessageBox()">X</a>
            </h1>
          </div>
          <div class="form-group">
            <label for="message">{{ labels[country].sendamessagewhat }}</label>
            <textarea id="message" name="message" class="form-control" rows="5" required></textarea>
          </div>
          <div class="mt-4" style="text-align:right">
            <input type="submit" :value="labels[country].sendamessagesend" />
          </div>
        </form>
      </div>
      <footer class="film mt-4 p-4 d-flex justify-content-between align-items-center">
        <div>
          <img id="f-logo" src="../assets/Logo_showzam_512.png" />
          <p>Copyright 2022 &copy;</p>
        </div>
        <h1 class="text-center"><a @click="sendMessageBox()">{{ labels[country].sendamessage }}</a></h1>
        <div class="text-center">
          <p id="social" class="d-flex justify-content-center align-items-center">
            <a target="_blank" href="https://twitter.com/ShowzamSimpsons">
              <img src="../assets/twitter.png" />
            </a>
            <a target="_blank" href="https://www.facebook.com/profile.php?id=100087392330306">
              <img src="../assets/facebook.png" />
            </a>
            <a target="_blank" href="https://www.instagram.com/showzam_simpsons/">
              <img src="../assets/instagram.png" />
            </a>
          </p>
          <p><a target="_blank" :href="'https://www.gdprset.it/info/gdpr-privacy-'+twoDigitsCountry+'-444/'">Privacy Policy</a></p>
        </div>
      </footer>
    </div>

    <div id="flags" class="d-flex flex-column">
      <a class="my-1" @click="setCountry('USA')"><img src="../assets/USA.png"/></a>
      <a class="my-1" @click="setCountry('ITA')"><img src="../assets/ITA.png"/></a>
      <a class="my-1" @click="setCountry('DEU')"><img src="../assets/DEU.png"/></a>
    </div>
      
    <div class="boxContainer" id="sending" v-if="sending"></div>
    
  </div>

</template>

<script>
import axios from 'axios'
import $ from 'jquery'
import StrangerThingsHome from './StrangerThingsHome.vue'

export default {
  name: 'StrangerThings',
  components: {
    StrangerThingsHome
  },
  data() {
    return {
      show: "stranger_things",
      labels: {},
      country: null,
    }
  },
  computed: {
    twoDigitsCountry(){
      let c = this.country.toLowerCase().substring(0,2);
      return c =='us' ? 'en' : c
    }
  },
  methods: {
    getLabels(){
      axios.post('api/labels.php',{show: this.show})
      .then(res => {
        this.labels = res.data;
        Object.keys(this.labels).forEach((l) =>{
          this.labels[l].helptext = this.labels[l].helptext.replace('CTRL-V', '<span style="color:#a70101">CTRL-V</span>');
          this.labels[l].guidelines = this.labels[l].guidelines.replace(/\n/gm, '<br/>');
        })
      });
    },
    setCountry(c){
      localStorage.setItem('country',c)
      this.country = c
      this.updatePage();
      this.gdpr();
    },
    sendMessageBox(){
      let form = $('#sendmessageForm');
      form.removeClass("was-validated");
      form.unbind()
      /*
      let form = document.querySelector("#sendmessageForm");
      form.classList.remove("was-validated");
      form.removeEventListener('submit');
      */
      if($("#sendMessageFormContainer").hasClass("d-none")){
        $("#sendMessageFormContainer").removeClass("d-none");
        form.on('submit', (event) =>{
          event.preventDefault();
          event.stopPropagation();
          let jsform = document.querySelector("#sendmessageForm");
          let validity = jsform.checkValidity()
          jsform.classList.add('was-validated');
          if(validity === true) {
            let question = prompt(this.createCaptchaValue()+" = ?");
            if(question != this.captchaValue)
              return;
            this.sendMessage(new FormData(jsform));
          }
          return false;
        });
      }
      else{
        $("#sendmessageForm input, #sendmessageForm textarea").not(':input[type=button], :input[type=submit]').val("");
        $("#sendMessageFormContainer").addClass("d-none");
      }
    },
    async sendMessage(data){
      data.append('show', "The Simpsons");
      this.sending = true;
      await axios.post('api/sendMessage.php', data)
      .then(async () => {
        this.sending = false;
        setTimeout(() => {
          alert(this.labels[this.country].sendamessagesent);
          this.sendMessageBox();
        }, 100);
        
      }).catch(() => {
        this.sending = false;
        alert(this.labels[this.country].error);
      })
    },
    
    updatePage() {
      document.title = `Showzam - ${this.labels[this.country].pagetitle}`;
      document.querySelector('meta[name="description"]').setAttribute("content", this.labels[this.country].pagedesc);
      $('html').prop("lang", this.country.toLowerCase().substring(0,2)); 
    },
    gdpr(){
      if(document.getElementById("gdpr") != null){
        document.getElementById("gdpr").remove();
      }

      let tag = document.createElement("script");
      tag.setAttribute("id", "gdpr");
      tag.setAttribute("src", "https://www.gdprset.it/widget/gdpr-"+this.twoDigitsCountry+"-444.js");

      document.body.appendChild(tag);
    }
  },
  mounted() { 
    this.country = this.$root.country
    this.getLabels();
    this.gdpr();
  },
  updated() {
    this.updatePage();
  }
}
</script>

<style scoped>

@font-face {
    font-family: 'Crimson';
    src: url('../assets/Crimson-SemiboldItalic.woff2') format('woff2'),
        url('../assets/Crimson-SemiboldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Crimson';
    src: url('../assets/Crimson-Semibold.woff2') format('woff2'),
        url('../assets/Crimson-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Crimson';
    src: url('../assets/Crimson-Roman.woff2') format('woff2'),
        url('../assets/Crimson-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Crimson';
    src: url('../assets/Crimson-Italic.woff2') format('woff2'),
        url('../assets/Crimson-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Crimson';
    src: url('../assets/Crimson-BoldItalic.woff2') format('woff2'),
        url('../assets/Crimson-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Crimson';
    src: url('../assets/Crimson-Bold.woff2') format('woff2'),
        url('../assets/Crimson-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

#stranger-things{
  /*font-family:  "Comic Sans MS", "Comic Sans", "Chewy", 'Comic Neue', cursive;*/
  font-family:  "Crimson", sans;
  color: white;
  background-image: url('../assets/sfondo.png');
  background-repeat: repeat-y;
  background-size: 100% 
}

.navbar-expand{
  font-size: 1.5em !important;
}

.navbar-nav .nav-link
{
  color: #fff !important;
}

.navbar-nav .nav-link:hover,
footer a:hover
{
  color: #fb0 !important;
}

.wrapper{
  margin: 0 auto;
  width: 960px;
}

.film{
  position: relative;
  z-index: 10;
  display: block;
  height: 205px;
  background-image: url("../assets/film.png");
  background-position: center;
  background-repeat: repeat-x;
}

#logo{
  position: absolute;
  display: block;
  width: 512px;
  height: 122px;
  left: 50%;
  top: 0;
  margin-left: -339px;
  margin-top: 42px;
  background-image: url("../assets/Logo_showzam_512.png");
  background-repeat: no-repeat;
}

#title-image{
  position: absolute;
  display: block;
  width: 594px;
  height: 171px;
  left: 50%;
  top: 0;
  margin-left: -277px;
  margin-top: 176px;
  background-image: url("../assets/stranger_things_logo.png");
  background-repeat: no-repeat;

}

.flex-end{
  height: 100%;
  align-items: flex-end;
}

a{
  cursor: pointer;
  text-decoration: none;
  color: white;
  font-weight:  normal;  
}

/*
#sending{
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../assets/homer.gif);
}
*/

.guidebox {
  box-sizing: border-box;
  /*font-family:  "Comic Sans MS", "Comic Sans", "Chewy", 'Comic Neue', cursive;*/
  border-radius: 24px;
  padding: 0 2em 0.6em 2em;
}

.guidebox h1{
  /*font-family: "Chewy", "Comic Sans MS", "Comic Sans", 'Comic Neue', cursive;*/
  font-weight:  bold;
  font-size: 3em;
}

.guidebox p {
  /*font-family: "medranoregular", "Comic Sans MS", "Comic Sans", 'Comic Neue', cursive;*/
  font-weight:  bold;
  font-size: 1.5em;
  line-height: 1.2em;
}

.grayrect{
  box-sizing: border-box;
  background-color: #666;
  border: 10px solid #fb0;
  font-weight: bold;
}

.boxContainer{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;  
  z-index: 100;
}

#sendmessageForm{
  position:relative; 
  width: 896px;
}

#flags{
  position: fixed;
  z-index: 300;
  right: 0px;
  top: 24px;
  background-color: rgba(255,255,255,0.8);
  padding: 8px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  box-shadow: 0px 0px 10px #000;
}

#flags img{
  cursor: pointer;
  width: 48px;
}

footer #f-logo{
  width: 224px;
  margin-bottom: 1em;
}

footer p {
  font-size: 1.4em;
  line-height: 1em;
  margin-bottom: 0.3em;
}
#sendMessageFormContainer{
  z-index: 20;
}

#sendMessageFormContainer form{
  padding: 2em;
}

#sendMessageFormContainer form input[type=submit]{
  font-size: 2em;
  padding: 0 0.6em;
  border-radius: 2em;
  border: 2px solid #222;
  color: #666;
}

footer #social a img{
  width:48px;
  margin:0.3em;
}


@media (max-width: 974px) {
  .wrapper{
    margin: 0 auto;
    width: 100%;
  }

  .film{
    height: 21vw;
    background-image: url("../assets/film.png");
    background-size: 25vw;
  }

  .guidebox h1{
    /*font-family: "Chewy", "Comic Sans MS", "Comic Sans", 'Comic Neue', cursive;*/
    font-weight:  bold;
    font-size: 5vw;
  }

  .guidebox p {
    /*font-family: "medranoregular", "Comic Sans MS", "Comic Sans", 'Comic Neue', cursive;*/
    font-weight:  bold;
    font-size: 3vw;
    line-height: 1.2em;
  }

  #logo{
    width: 52.5vw;
    height: 12.5vw;
    background-size: cover;
    margin-left: -34.7vw;
    margin-top: 4.3vw;
  }

  #title-image{
    width: 61vw;
    height: 17.4vw;
    background-size: cover;
    margin-left: -28.3vw;
    margin-top: 18vw;
  }

  #sendmessageForm{
    position:relative; 
    width: 95%;
  }

  #flags img{
    cursor: pointer;
    width: 4.5vw;
  }

  footer #f-logo{
    width: 23vw;
    margin-bottom: 1.5vw;
  }

  footer p {
    font-size: 2.5vw;
  }

  footer h1 {
    font-size: 4vw;
  }

  footer #social a img{
    width:32px;
  }

}
</style>
