import Vue from 'vue'
import Router from 'vue-router'
import TheSimpsons from '../components/TheSimpsons'
import TheSimpsonsHome from '../components/TheSimpsonsHome'
import FrameStories from '../components/FrameStories'
import StrangerThings from '../components/StrangerThings'
import StrangerThingsHome from '../components/StrangerThingsHome'
Vue.use(Router)

export default new Router({
	mode: 'hash',
	routes: [
		{
			path: '/the_simpsons',
			name: 'TheSimpsons',
			component: TheSimpsons,
			children: [
				{
					path: 'frame_stories/:id?/:title?',
					component: FrameStories
				},
				{
					path: ':id?',
					component: TheSimpsonsHome
				},

			]
		},
		{
			path: '/stranger_things',
			name: 'StrangerThings',
			component: StrangerThings,
			children: [
				{
					path: ':id?',
					component: StrangerThingsHome
				},

			]
		},
		{
			path: '/',
			redirect: '/the_simpsons',
		},
	]
})
