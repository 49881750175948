<template>
  <div>
    <div class="sbcontainer">
      <div id="bgSearchBox">
        <div id="inMacchinario" class="d-flex justify-content-center flex-end" >
          <a class="grayrect xmark" :style="'visibility: '+visibility" @click="reset()">X</a>
          
          <div class="grayrect search" id="searchBox">
            <div class="inner">
              <div v-if="showHelpText" id="helptext" v-html="labels[country].helptext">{{ labels[country].helptext }}
              </div>
              <div v-if="pasted" class="spinner-border" style="color: #a70101" role="status">
              </div>
            </div>
            <form v-if="showHelpText" method="post" action="" enctype="multipart/form-data">
              <input v-if="uploading==false" type="file" name="file" id="file" accept="image/png, image/jpeg" @change="uploadImg($event)"/>
            </form>
            
          </div>
          <a class="grayrect questionmark" @click="showHelpBox()">?</a>
        </div>
      </div>
    </div>

    <Ads :labels="labels" :country="country" :adsense="false"/>
    <!--
    <div v-if="!uploading && Object.keys(results).length == 0" id="results">
      <h1 id="result-link" style="font-size:4em">&nbsp;</h1>
      <div id="results-contents">
        <div class="d-flex align-items-center">
          <div class="grayrect imgFound flex-shrink-0">
          </div>
        </div>
      </div>
    </div>
    -->
    <div v-if="uploading" id="results">
      <h1 id="result-link" style="font-size:4em">{{ labels[country].searching }}</h1>
      <div id="results-contents">
        <div class="d-flex align-items-center">
          <div class="grayrect imgFound d-flex align-items-center justify-content-center flex-shrink-0" style="background-color: #3c3c3c">
            <img src="../assets/Searching_animato.gif" style="max-width:100%">
          </div>
        </div>
      </div>
    </div>
    <div v-if="!uploading && Object.keys(results).length > 0" id="results">
      <div>
        <h1 id="result-link" style="font-size: 5em" v-if="top.src && !top.uncertain">{{ labels[country].result }}</h1>
        <h1 id="result-link" style="font-size: 4em" v-if="top.src && top.uncertain">{{ labels[country].result }} ({{ labels[country].uncertain }})</h1>
        <h1 id="result-link" style="font-size: 3em" v-if="results.notfound">{{ labels[country].notFound }}</h1>

        <div id="results-contents">
          <div v-if="top.src" id="found">
            <div class="d-flex align-items-stretch mb-2">
              <div class="grayrect imgFound flex-shrink-0" id="imgFound">
                <a id="newSearch" @click="reset()">{{ labels[country].newSearch }}</a>
              </div>
              <div class="w-100" v-if="top.src" v-html="textResult"></div>
            </div>
            <div v-if="top.content[country].link" style="line-height:1.2em; font-size: 0.6em;word-wrap: break-word;">
              <p style="color:#ccc">{{ labels[country].link }}:<br/>
                <a style="text-decoration:underline" target="_blank" :href="top.content[country].link">{{ top.content[country].link | notHash }}</a>
              </p>
            </div>
            <div id="thumbs" class="d-flex justify-content-center" v-if="results.length > 1">
              <div id="mainResult">
                <h1 class="text-center mb-0">{{ labels[country].mainresult }}</h1>
                <div class="d-flex p-1" style="border-right: 3px solid #293646;">
                  <a class="grayrect imgFound imgSm selected m-2" :style="'background-image: url(' + results[0].src + ')'" :data-rel="0" @click="switchTo(0)" :title="`${results[0].similarity}%, ${results[0].round}`">
                  </a>
                </div>
              </div>
              
              <div id="otherResult" class="p-1">
                <h1 class="text-center mb-0">{{ labels[country].otherresults }}</h1>
                <div class="d-flex flex-wrap justify-content-center">
                  <a v-for="(result,i) in results.slice(1)" :key="i" class="grayrect imgFound imgSm m-2" :style="'background-image: url(' + result.src + ')'" :data-rel="i+1" @click="switchTo(i+1)" :title="`${result.similarity}%, ${result.round}`">
                  </a>
                </div>
              </div>
            </div>
            <div v-if="this.results[0].sid" id="feedback" class="grayrect text-center mt-2 p-1" >
              {{ this.labels[country].feedbackQuestion }} 
              <a class="mx-1" @click="feedback(true)">{{ labels[country].feedbackRight }}</a>
              &nbsp;|&nbsp;
              <a class="mx-1" @click="feedback(false)">{{ labels[country].feedbackWrong }}</a>
            </div>
          </div>
          
          <div v-if="results.notfound" class="guidebox grayrect" id="notfoundbox">
            <h1 v-html="labels[country].notfoundboxtitle" class="text-center"></h1>
            <p v-html="labels[country].guidelines" class="mb-0"></p>
            <h1 style="text-align:right"><a @click="hideNotFoundBox()">OK</a></h1>
          </div>
        </div>

      </div>
    </div>
    <div class="boxContainer d-none" id="helpboxcontainer">
      <div id="helpbox" class="guidebox grayrect">
        <h1 v-html="labels[country].helpboxtitle" class="text-center"></h1>
        <p v-html="labels[country].helpboxcontent"></p>
        <p v-html="labels[country].tutorialpreTextLink"></p>
        <p v-html="labels[country].guidelines" class="mb-0"></p>
        <h1 style="text-align:right"><a @click="hideHelpBox()">OK</a></h1>
      </div>
    </div>



      
  </div>

</template>

<script>
import axios from 'axios'
import $ from 'jquery'
import Ads from './Ads'

export default {
  components: {
    Ads
  },
  name: 'StrangerThingsHome',
  props: ['labels', 'country', 'show'],
  watch: { 
    country: function() { 
      this.updateTextResult()
    }
  },
  data() {
    return {
      upStart: null,
      upEnd: null,
      
      results: {},
      top: {},
      showHelpText: true,
      pasted: false,
      uploading: false,
      textResult: null,
      showData: null,
      //country: null,
      sending: false,
      captchaValue: 0,
    }
  },
  computed: {
    twoDigitsCountry(){
      let c = this.country.toLowerCase().substring(0,2);
      return c =='us' ? 'en' : c
    },
    visibility(){
      return this.results.notfound || this.top.src ? 'visible' : 'hidden'
    }
  },
  methods: {
    getRandomArbitrary(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    getNow() {
      return Math.round(Date.now() / 1000); 
    },
    async sleep(ms){
      return new Promise(
        resolve => setTimeout(resolve, ms)
        );
    },
    reset(){
      this.results = {};
      this.top = {};
      this.upStart = null;
      this.showHelpText = true;
      this.$router.push({ path: `/${this.show}`, replace: true });
      document.getElementById("searchBox").style.backgroundImage = '';
    },
    uploadImg(e) {
      if(e.target.files.length == 0 || this.uploading || this.pasted){
        return
      }
      let file = e.target.files[0]
      this.preUpload(file);
    },
    async preUpload(file) {
      this.pasted = true;
      this.showHelpText = false
      let ms = (this.getRandomArbitrary(1000,2000));
      //console.log(`must wait ${ms} milliseconds...`);
      await this.sleep(ms);
      this.upload(file);
    },
    upload(file) {
      setTimeout( () => {
        $('html, body').animate({scrollTop: $("#results").offset().top}, 500);
      },500);
      
      this.pasted = false;
      let src = URL.createObjectURL(file);
      this.preview(src);
      this.upStart = this.getNow();
      this.upEnd = this.upStart + this.getRandomArbitrary(8,11);  

      //console.log(this.upStart, this.upEnd);
      let fd = new FormData();
      fd.append('myImage', file, file.name);
      fd.append('show', this.show);

      console.log(this.uploading);
      if(!this.uploading){
        this.uploading = true;
        axios.post('api/upload.php', fd)
        .then(async res => {
          let n = this.getNow();
          //console.log(n, n < this.upEnd)
          
          if(n < this.upEnd){
            let ms = (this.upEnd - n)*1000;
            //console.log(`must wait ${ms} milliseconds...`);
            await this.sleep(ms);
          }

          //console.log("ok, next!");
            
          this.uploading = false
          this.results = res.data
          
          if(this.results.notfound){
            this.top = {};
            $('html, body').animate({scrollTop: $("#results").offset().top}, 25);
            return
          }
          this.$router.push({ path: `/${this.show}/${this.results[0].sid}`, replace: true });
          this.switchTo(0,true);
        }).catch(() => {
          this.uploading = false;
          alert(this.labels[this.country].error);
          this.reset();
        })
      }

    
    },
    preview(src){
      let preview = document.getElementById("searchBox")
      preview.style.backgroundImage = `url(${src})`;
    },
    _createTextResult(key, value){
      if(value === undefined){
        value = this.top.content[this.country][key];
      }
      key = this.labels[this.country][key] === undefined ? key : this.labels[this.country][key];
      return `
        <div class="mb-0 d-flex justify-content-between" style="line-height:1em">
          <div style="color:#ccc; font-size:0.6em">${key}:</div>
          <div style="text-align:right">${value}</div>
        </div>
      `;
    },
    switchTo(i, blockScroll) {
      $("#thumbs a").removeClass("selected");
      $(`#thumbs a[data-rel=${i}]`).addClass("selected");
      this.top = this.results[i];
      this.updateContents();
      if(!blockScroll)
        $('html, body').animate({scrollTop: $("#results").offset().top}, 25);
    },
    updateContents() {
      if( Object.keys(this.results).length == 0){
        return
      }

      setTimeout(()=>{
        let preview = document.getElementById("imgFound")
        preview.style.backgroundImage = `url(${this.top.src})`;
      },50)

      this.updateTextResult();
      $('html, body').animate({scrollTop: $(window).scrollTop()}, 25);
    },
    updateTextResult(){
      if(this.top.content === undefined)
        return;
      //let labels = this.labels[this.country]
      let content = this.top.content[this.country]
      this.textResult = '';
      if (content.season != "0") {
        let seasonEpisode = {
          label: `${this.labels[this.country]['season']}/${this.labels[this.country]['episode']}`,
          value: `${this.top.content[this.country]['season'].padStart(2, '0')}x${this.top.content[this.country]['episode'].padStart(2, '0')}`
        };
        
        this.textResult += this._createTextResult(seasonEpisode.label, seasonEpisode.value);
        
      } else {
        this.textResult += this._createTextResult(this.labels[this.country]['episode'], this.top.content[this.country]['episode'].padStart(2, '0'));
      }
      this.textResult += this._createTextResult('title');
      if(content.production_code) {
        this.textResult += this._createTextResult('production_code');
      }

      this.textResult += this._createTextResult('time', this.format(this.top.time));
    },
    format(time){
      let parts = time.split(":");
      let fTime = `${parts[1]}m ${parts[2]}s`;
      if(parts[0] != '00')
        fTime = `${parts[0]}h ${fTime}`;
      return fTime;
    },
    showHelpBox() {
      $("#helpboxcontainer").removeClass("d-none");
    },
    hideHelpBox() {
      $("#helpboxcontainer").addClass("d-none");
    },
    hideNotFoundBox() {
      this.reset();
      $('html, body').animate({scrollTop: $("#the-simpsons").offset().top}, 25);
    },
    createCaptchaValue(){
      let left = Math.round(Math.random()*9)+1;
      let right = Math.round(Math.random()*9)+1;
      let operations = ['+','-','*'];
      let i = Math.round(Math.random()*2);

      if(i==1 && left < right){
        let t= right;
        right = left
        left = t; 
      }


      let selectedOperation = operations[i];
      let operation = `${left} ${selectedOperation} ${right}`;
      this.captchaValue = eval(operation);
      return operation.replace("*","x");
    },
    feedback(result){
      let id = this.results[0].sid;
      this.results[0].sid = false;
      axios.post('api/feedback.php', {show: this.show, id: id, feedback: result})
      .then(() => {
        alert(this.labels[this.country].feedbackThankYou)
      }).catch(() => {
        alert(this.labels[this.country].error);
      })
    },
    getParams(){
      if(this.$route.params.id !== undefined)
        this.getSearch(this.$route.params.id);
      else
        this.showHelpText = true;
    },
    init(){
      this.getParams()
      //console.log(this.labels[l].helptext)
      console.log("adding event listener");
      window.addEventListener('paste', this.pasteEvent);
      

    },
    pasteEvent(e){

      let clipboardData = e.clipboardData;
      let file = null;
      for(let i=0; i<clipboardData.items.length; i++){
        if(clipboardData.types[i] == 'Files'){
          file = clipboardData.items[i].getAsFile();
          break;
        }
      }

      if(file !== null)
        this.preUpload(file);
    
    },
    async getSearch(id){
      this.showHelpText = false;
      this.pasted = true;
      await axios.post('api/search.php', {id: id})
      .then(async (res) => {
        this.pasted = false;
        let data = res.data;
        if(data.frame === undefined){
          this.reset();
          return;
        }
        this.preview(data.frame);
        this.results = data.results;
        this.switchTo(0,true);
        setTimeout( () => {
          $('html, body').animate({scrollTop: $("#results").offset().top}, 500);
        },500);
      }).catch(() => {
        alert(this.labels[this.country].error);
      })
    }
  },
  beforeCreate () {
    document.querySelector('body').setAttribute('style', 'background-color:#243f54')
  },
  beforeDestroy () {
    console.log("removing event listener");
      
    window.removeEventListener('paste', this.pasteEvent);
    document.querySelector('body').setAttribute('style', '')
    $("#l-home").unbind();
  },
  mounted() {        
    this.init();
    $("#l-home").click(() => {
      this.reset()
    })
  },
  filters: {
    notHash: function (link) {
      if (!link) return ''
      return link.substring(0,link.indexOf("#"));
    }
  }

}
</script>

<style scoped>

.wrapper{
  margin: 0 auto;
  width: 960px;
}

.film{
  position: relative;
  z-index: 10;
  display: block;
  height: 205px;
  background-image: url("../assets/film.png");
  background-position: center;
  background-repeat: repeat-x;
}

#logo{
  position: absolute;
  display: block;
  width: 512px;
  height: 122px;
  left: 50%;
  top: 0;
  margin-left: -339px;
  margin-top: 42px;
  background-image: url("../assets/Logo_showzam_512.png");
  background-repeat: no-repeat;
}

#title-image{
  position: absolute;
  display: block;
  width: 435px;
  height: 105px;
  left: 50%;
  top: 0;
  margin-left: -158px;
  margin-top: 132px;
  background-image: url("../assets/scritta_simpsons.png");
  background-repeat: no-repeat;

}

#bgSearchBox{
  position: relative;
  margin: 0 auto;
  display: block;
  z-index: 0;
  width: 960px;
  height: 564px;
  background-image: url('../assets/sfondo_caricamento.png');
  background-repeat:  no-repeat;
  background-position: bottom center;
}


.flex-end{
  height: 100%;
  align-items: flex-end;
}

#inMacchinario{
  padding-bottom: 84px;
}

.grayrect{
  box-sizing: border-box;
  background-color: #3c3c3c;
  border: 10px solid #a70101;
  font-weight: bold;
}

.search,
.imgFound{
  position: relative;
  border-radius: 24px;
  width: 410px;
  height: 294px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.imgSm{
  width: 218px;
  height: 172px;
  border-width: 5px;
  
}

.imgSm.selected{
 border-color: #fff;
}

#helptext{
  font-family: 'Passion One';
  font-size: 3em;
  line-height: 1em;
  font-weight: normal;
}

#feedback{
  font-size: 0.8em;
  border-radius: 2em;
  background-color: transparent;
  border-width: 0.15em;
}

#feedback a{
  text-decoration: underline;
}

#newSearch{
  position:absolute; 
  bottom:0; 
  right:0;
  text-decoration: underline;
  /*background-color: rgba(0,0,0,0.6);*/
  font-size: 0.6em;
  line-height: 1em;
  padding: 0.3em;
  border-radius: 0 0 0.6em 0;
}
.questionmark,
.xmark{
  font-family: 'Passion One';
  font-size: 4em;
  border-radius: 4em;
  margin-left: 16px;
  margin-right: 16px;
  padding: 0em 0.3em;
  padding-top: 0.1em;
  color: white;
  text-decoration: none;
  line-height: 1em;
}

a{
  cursor: pointer;
  text-decoration: none;
  color: white;
  font-weight:  normal;  
}

.search .inner{
  display: flex;
  height: 100%;
  padding: 0.6em;
  text-align: center;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

#homer, #sending{
  background-position: center;
  background-repeat: no-repeat;
}


.search input{
  opacity: 0; /* invisible but it's there! */
  position: absolute;
  top: 0;
  background-color: red;
  width: 100%;
  height: 100%;
  cursor: pointer;
  font-size: 0.1em;
}

#results{
  position: relative;
  margin-bottom: 40px;
  background-image: url('../assets/sfondo_risultato.png');
  background-repeat:  no-repeat;
  min-height:605px;
}

#result-link{
  padding: 0;
  margin: 0;
  text-align: center;
  font-weight: bold;
  line-height: 119px;
  color: #042531;
  text-align:center;
  text-shadow: -2px -2px 6px #df1507, -1px -1px #df1507, 0px -2px #df1507, 1px -2px #df1507, 2px -2px 6px #df1507,
              -2px 2px 6px #df1507, -1px 2px #df1507, 0px 2px #df1507, 1px 2px #df1507, 2px 2px 6px #df1507
}

#found{
  font-size: 2.5em;
  line-height: 1.5em;
}

#imgFound{
  margin-right: 8px;
}


#loader{
  position: fixed;
  top: 100%;
  left: 50%;
  width: 320px;
  height: 320px;
  margin:-320px 0 0 -160px;
  z-index:200;
}

.boxContainer{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;  
  z-index: 100;
}

#helpboxcontainer{
  z-index: 300;
}

#helpbox,
#sendmessageForm{
  position:relative; 
  width: 896px;
}

.guidebox {
  box-sizing: border-box;
  /*font-family:  "Comic Sans MS", "Comic Sans", "Chewy", 'Comic Neue', cursive;*/
  border-radius: 24px;
  padding: 0 2em 0.6em 2em;
}

.guidebox h1{
  /*font-family: "Chewy", "Comic Sans MS", "Comic Sans", 'Comic Neue', cursive;*/
  font-weight:  bold;
  font-size: 3em;
}

.guidebox p {
  /*font-family: "medranoregular", "Comic Sans MS", "Comic Sans", 'Comic Neue', cursive;*/
  font-weight:  bold;
  font-size: 1.5em;
  line-height: 1.2em;
}


#flags{
  position: fixed;
  z-index: 300;
  right: 0px;
  top: 24px;
  background-color: rgba(255,255,255,0.8);
  padding: 8px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  box-shadow: 0px 0px 10px #000;
}

#flags img{
  cursor: pointer;
  width: 48px;
}

footer #f-logo{
  width: 224px;
  margin-bottom: 1em;
}

footer p {
  font-size: 1.4em;
  line-height: 1em;
  margin-bottom: 0.3em;
}
#sendMessageFormContainer{
  z-index: 20;
}

#sendMessageFormContainer form{
  padding: 2em;
}

#sendMessageFormContainer form input[type=submit]{
  font-size: 2em;
  padding: 0 0.6em;
  border-radius: 2em;
  border: 2px solid #222;
  color: #666;
}




@media (max-width: 974px) {
  .wrapper{
    margin: 0 auto;
    width: 100%;
  }

  .film{
    height: 21vw;
    background-image: url("../assets/film.png");
    background-size: 25vw;
  }

  #logo{
    width: 52.5vw;
    height: 12.5vw;
    background-size: cover;
    margin-left: -34.7vw;
    margin-top: 4.3vw;
  }

  #title-image{
    width: 61vw;
    height: 17.4vw;
    background-size: cover;
    margin-left: -28.3vw;
    margin-top: 18vw;
  }

  #bgSearchBox{
    position: relative;
    margin: 0 auto;
    display: block;
    z-index: 0;
    background-size: 100vw;
    width: 100vw;
    height: 58.6vw;
  }

  #prof{
    top: 4.8vw;
    left: 3.3vw;
    width: 17.4vw;
    height: 48.5vw;
    background-size:18.2vw;
  }

  #inMacchinario{
    padding-bottom: 8.8vw;
  }

  .grayrect{
    border-width: 1.1vw;
  }

  .search .inner{
    padding: 1.1vw;
  }

  .search,
  .imgFound{
    position: relative;
    border-radius: 2vw;
    width: 44vw;
    height: 31vw;
    
  }

  #helptext{
    font-size: 4.9vw;
    line-height: 1em;
  }

  .questionmark,
  .xmark{
    font-size: 6.6vw;
    border-radius: 4em;
    margin-right: 3.1vw;
    margin-left: 1.6vw;
  }

  .xmark{
    margin-right: 1.6vw;
  }

  #helpbox,
  #sendmessageForm{
    position:relative; 
    width: 95%;
  }

  #results{
    font-size: 1.65vw;
    min-height: 62vw;
    background-size: 100vw;
  }

  .guidebox h1{
    font-weight:  bold;
    font-size: 5vw;
  }

  .guidebox p {
    font-weight:  bold;
    font-size: 3vw;
    line-height: 1.2em;
  }

  #result-link{
    background-position: 2.5vw center;
    background-size: 34vw;
    padding-left: 36vw;
    line-height: 12.5vw;
  }

  #flags img{
    cursor: pointer;
    width: 4.5vw;
  }

  footer #f-logo{
    width: 23vw;
    margin-bottom: 1.5vw;
  }

  footer p {
    font-size: 2.5vw;
  }

  footer h1 {
    font-size: 4vw;
  }
}
</style>
