import Vue from 'vue'
import router from './router'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import axios from 'axios'
import App from './App.vue'
import Ads from 'vue-google-adsense'

//import titleMixin from './mixins/titleMixin'
axios.defaults.headers.common['Authorization'] = 'Basic c2V4dHJhY3RvcjohX1MzeDdSNGNUMHJfIQ=='
window.axios = axios

Vue.config.productionTip = false
//Vue.mixin(titleMixin)

// var localStorage = window.localStorage
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)
new Vue({
  router,
  render: h => h(App),
  computed: {
    country() {
      
      if(!localStorage.getItem('country'))
        localStorage.setItem('country', 'USA')
      return localStorage.getItem('country')

    }
  }
}).$mount('#app')
