<template>
  <div>
    <div v-if="stories == null" class="text-center spinner-border" style="color: #fff" role="status">
    </div>
    
    <div v-if="stories != null">
      <h1 v-if="!stories[country]">No Frame Stories for this language...</h1>
      <div v-if="stories[country] && stories[country] && the_story == null">
        <ul class="list-group">
          <li class="list-group-item" v-for="(story, i) in stories[country]" :key="i">
            <div class="fscontainer d-flex align-items-center" @click="readMore(story.id)">
              <img :src="story.thumb" class="rounded img-thumbnail">
              <div class="mx-2">
                <h1>{{story.title}}</h1>
                <p style="margin-bottom:0">
                  {{story.intro}} <span style="color: #fb0">...{{labels[country].readmore}}...</span>
                </p>
              </div>
            </div>
            <Ads class="mt-2" v-if="i>1 && (i+1)%3==0 & i<(stories[country].length-1)" :labels="labels" :country="country" :adsense="adsense"/>
          </li>
        </ul>
        
      </div>
      <Ads v-if="the_story==null && stories[country] && stories[country].length < 3" :labels="labels" :country="country" :adsense="adsense"/>
      <div v-if="stories[country] && the_story != null">
        
        <div v-for="(html,i) in the_story.html" :key="i">
          <div v-html="html" class="article-part"></div>
          <Ads v-if="i < (the_story.html.length - 1)" style="margin-bottom:0.6em" :labels="labels" :country="country" :adsense="adsense"/>
        </div>
        <p><a class="btn btn-primary" @click="reset()">{{ labels[country].backToList }}</a></p>

      </div>
    </div>
      
  </div>
</template>

<script>
import axios from 'axios'
import Ads from './Ads'
import $ from 'jquery'

export default {
  components: {
    Ads
  },
  name: 'FrameStories',
  props: ['labels', 'country', 'show'],
  data() {
    return {
      stories: null,
      the_story: null
    }
  },
  computed: {
    pp() {
      return {
        link: this.country == 'ITA' ? 
          `https://www.paypalobjects.com/it_IT/IT/i/btn/btn_donate_LG.gif` :
          `https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif`
      }
    },
    adsense(){ return false }
  },
  methods: {
    getStories(){
      axios.post('api/frame-stories.php',{show: this.show})
      .then(res => {
        this.stories = res.data;

        for (let country in this.stories) {
          this.stories[country].map((story) => {
            let htmls = story.html.split("###ADS###")
            story.html = htmls;
          });
        }

        if(this.$route.params.id !== undefined && this.$route.params.title !== undefined)
          this.readMore(this.$route.params.id);
      });
    },
    readMore(i){

      let story = this.stories[this.country].filter((el) => {if(el.id == i) return el}).pop();
      
      this.the_story = story  

      $('html, body').animate({scrollTop: 0}, 10);
      
      if(this.$route.params.id === undefined)
        this.$router.push({ path: `/${this.show}/frame_stories/${story.id}/${story.title.replace(/ /g,"_")}`});
    },
    reset() {
      this.$router.push({ path: `/${this.show}/frame_stories/`});
      this.the_story = null;
    }
  },

  mounted() {
    this.getStories();
    $("#l-frame-stories").click(() => {
      this.the_story = null;
    })
  },
  updated() {
    if(this.$route.params.id !== undefined)
      this.readMore(this.$route.params.id);
  },
  beforeDestroy () {
    $("#l-frame-stories").unbind();
  },
  

}
</script>

<style scoped>

div.article-part >>> h2{
  font-size: 2.5em !important;
}

div.article-part >>> p{
  font-family: "medranoregular", "Comic Sans MS", "Comic Sans", "Comic Neue", cursive !important;
  font-size: 1.5em;
  font-weight: normal;
  margin-bottom: 1em;
  line-height: 1.3em;
}

.img-thumbnail{
  max-width: 150px;
  background-color: #fb0;
  border-color: #fb0;
  padding: 0.10rem;
}

.list-group-item{
  border: none;
  background-color: transparent;
  padding: 0px;
}

.list-group-item .fscontainer{
  padding: 0.3rem 1rem;
  border: 1px solid rgba(0,0,0,0.5);
  color: white;
  margin-bottom: 0.3em;
  cursor: pointer;
}

.list-group-item .fscontainer:hover{
  background-color: rgba(255,255,255,0.2);
}

.text-right{
  text-align: right;
}

a.btn-primary{
  background-color: #fb0;
  border-color: #fb0;
  color: black;
}

</style>
