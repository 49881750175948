<template>
  <div class="film d-flex align-items-center justify-content-center">
    <div v-if="adsense" class="ad-container">
      <ins class="adsbygoogle"
           style="display:block"
           data-ad-client="ca-pub-3806520456091405"
           data-ad-slot="2828047544"
           data-ad-format="auto"
           data-full-width-responsive="true"></ins>
      <script type="application/javascript" data-free-gdpr="check">
           (adsbygoogle = window.adsbygoogle || []).push({});
      </script>

    </div>
    <div id="bannerBlender">
      <a href="https://www.blendermarket.com/creators/leledarevine" target="_blank">
        <img class="w-100" src="../assets/banner_blendermarket.png" />
      </a>
    </div>
    <form action="https://www.paypal.com/donate" method="post" target="_top">
      <input type="hidden" name="business" value="E38YF6DJPCXLJ" />
      <input type="hidden" name="no_recurring" value="0" />
      <input type="hidden" name="item_name" :value="labels[country].supporttheprojectlong" />
      <input type="hidden" name="currency_code" value="EUR" />
      <div class="grayrect text-center p-2" style="border-radius: 2em;border-width: 2px">
        <p class="m-0 pptext">{{ labels[country].supporttheproject }}</p>
        <div class="d-flex align-items-center justify-content-between my-1">
          <a href="https://www.paypal.com/it/webapps/mpp/paypal-popup" title="Paypal" class="mx-1"><img src="https://www.paypalobjects.com/webstatic/mktg/logo/pp_cc_mark_37x23.jpg" border="0" alt="PayPal Logo" id="ppLogo"/></a>
          <input type="image" :src="pp.link" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button"/>
        </div>
        <p class="m-0 pptext">{{ labels[country].formoreseries }}</p>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'Ads',
  props: ['labels', 'country','adsense'],
  computed: {
    pp() {
      return {
        link: this.country == 'ITA' ? 
          `https://www.paypalobjects.com/it_IT/IT/i/btn/btn_donate_LG.gif` :
          `https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif`
      }
    }
  }
}
</script>

<style scoped>

.film{
  position: relative;
  z-index: 10;
  display: block;
  height: 205px;
  background-image: url("../assets/film.png");
  background-position: center;
  background-repeat: repeat-x;
}

.grayrect{
  box-sizing: border-box;
  background-color: #666;
  border: 10px solid #fb0;
  font-weight: bold;
}

.pptext{
  color:white;
}

@media (max-width: 974px) {
  #ppLogo{
    width:  75%;
  }
  
  .pptext{
    display:  none;
  }

  .film{
    height: 21vw;
    background-image: url("../assets/film.png");
    background-size: 25vw;
  }
 
  .grayrect{
    border-width: 1.1vw;
  }

}

</style>
